


























































import VMessage from '@/components/VMessage.vue';
import VButton from '@/components/VButton.vue';
import { Component, Vue } from 'vue-property-decorator';
import ClubsApi, { ClubDto } from '@/api/Clubs';
import { UserRole } from '@/models/User';

@Component({
  components: { VMessage, VButton }
})
export default class NewClub extends Vue {
  public errors: string[] = [];
  public username = '';
  public email = '';
  public password = '';
  public password2 = '';
  public clubName = '';
  public loading = false;
  
  public submit() {
    this.loading = true;
    this.errors = [];

    if (this.username.length === 0) {
      this.errors.push('Username field is required');
    }

    if (this.username.length > 200) {
      this.errors.push('Username field must be less than 200 characters');
    }

    if (this.email.length === 0) {
      this.errors.push('Email field is required');
    }

    const re = /^[^\s@]+@[^\s@]+$/
    if (this.email.length > 0 && !re.test(this.email)) {
      this.errors.push('Email field is invalid');
    }

    if (this.password.length === 0) {
      this.errors.push('Password is required');
    }

    if (this.password !== this.password2) {
      this.errors.push('Passwords do not match');
    }

    if (this.password.length > 200 || this.password2.length > 200) {
      this.errors.push('Passwords must be < 200 characters');
    }

    if (this.clubName.length === 0) {
      this.errors.push('Club Name field is required');
    }

    if (this.clubName.length > 200) {
      this.errors.push('Club Name field must be < 200 characters');
    }

    if (this.errors.length > 0) {
      this.loading = false;
      return;
    }

    const data: ClubDto = {
      username: this.username,
      email: this.email,
      password: this.password,
      role: UserRole.COACH,
      clubName: this.clubName
    };

    ClubsApi.create(data).then(() => {
      this.$root.$emit('showSuccessMessage', 'New Club Created!');
      this.$router.push('/login');
    }).catch((error) => {
      if (error.response.status === 400) {
        this.errors.push(error.response.data.message);
      } else {
        this.$root.$emit('handleError', error);
      }
    }).finally(() => {
      this.loading = false;
    });
  }
}
