import axios from 'axios';
import Constants from '@/constants';
import { plainToClass } from 'class-transformer';
import { Club } from '@/models/Club';
import User, { UserRole } from '@/models/User';

export interface ClubDto {
  username: string;
  email: string;
  role: UserRole;
  password: string;
  clubName: string;
}

export default class ClubsApi {
  public static async getCurrentClub(): Promise<Club> {
    const response = await axios.get(`${Constants.api}/clubs/current`);
    return plainToClass(Club, response.data);
  }

  public static async updateClub(club: { id: number; name: string }): Promise<any> {
    return await axios.patch(`${Constants.api}/clubs/update`, club);
  }

  public static async create(clubDto: ClubDto): Promise<{ club: Club; user: User }> {
    return await axios.post(`${Constants.api}/clubs/create`, clubDto);
  }
}